
export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '50'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'tariffs',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'evses',
    label: '',
    Object: 'value',
    width: '100'
  }]
}
